<template>
    <div class="transfer-gate">
        <div class="container">
            <div class="d-flex justify-content-center">
                <img src="@/assets/demex-logo-big.png" class="responsive-img" />
            </div>
            <div class="d-flex justify-content-center">
                <h1 v-if="message" class="text-light">{{ message }}</h1>
            </div>
        </div>
    </div>
</template>
 
<style lang="css" scoped>
.responsive-img {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-bottom: 60px;
}
.transfer-gate {
        margin: -30px;
        padding-top: 60px;
        height: 103vh;
        background: #003440;
    }
</style>       

<script>
    import gateService from '@/services/gateService';
    export default {
        name: "transferGate",
        data: () => ({

            token: "",
            guid: "",
            message: ""
        }),
        methods: {
            checkTokens() {
                gateService.checkOwnershipToken({
                    token: this.token, guid: this.guid
                }).then(data => {
                    this.message = data;
                });
            },
        },
        mounted() {
            this.token = this.$route.query.token
            this.guid = this.$route.query.guid
            this.checkTokens();
        }
    }
</script>